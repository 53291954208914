import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import {
  Autocomplete,
  CardMedia,
  styled,
  TextField,
  Button,
  Paper,
  Typography,
  IconButton,
  Link,
} from "@mui/material";
import logo from "./assets/JDSlogo.webp";
import { useEffect, useRef, useState } from "react";
import background from "./assets/sideimage.png";
import { Field, Form, Formik } from "formik";
import Grid from "@mui/material/Grid2";
import ReCAPTCHA from "react-google-recaptcha";
import backgroundPaper from "./assets/tiling.png";

function MainPage(props) {
  const topHeaderRef = useRef(null);
  const footerRef = useRef(null);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);

  const [selectedLanguage, setSelecedLanguage] = useState("ಕನ್ನಡ");

  useEffect(() => {
    const topHeaderHeight =
      topHeaderRef.current && topHeaderRef.current.offsetHeight;

    const footerHeight = footerRef?.current && footerRef.current.offsetHeight;

    setFooterHeight(footerHeight);
    setHeaderHeight(topHeaderHeight);
  });

  const DrawerHeader = styled("div")(({ theme, open }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));

  const initial = {
    name: "",
    gender: "",
    constituency: "",
    constituency_sub: "",
    village: "",
    recaptcha: false,
  };

  const isKannada = selectedLanguage === "ಕನ್ನಡ";

  const genderList = isKannada
    ? ["ಗಂಡು", "ಹೆಣ್ಣು", "ಇತರರು"]
    : ["Male", "Female", "Others"];

  const constituencyList = [
    "Nippani",
    "Chikkodi-Sadalga",
    "Athani",
    "Kagwad",
    "Kudachi",
    "Raibag",
    "Hukkeri",
    "Arabhavi",
    "Gokak",
    "Yemkanmardi",
    "Belgaum Uttar",
    "Belgaum Dakshin",
    "Belgaum Rural",
    "Khanapur",
    "Kittur",
    "Bailhongal",
    "Saundatti Yellamma",
    "Ramdurg",
    "Mudhol",
    "Terdal",
    "Jamkhandi",
    "Bilgi",
    "Badami",
    "Bagalkot",
    "Hungund",
    "Muddebihal",
    "Devar Hippargi",
    "Basavana Bagevadi",
    "Babaleshwar",
    "Bijapur City",
    "Nagathan",
    "Indi",
    "Sindagi",
    "Afzalpur",
    "Jevargi",
    "Shorapur",
    "Shahapur",
    "Yadgir",
    "Gurmitkal",
    "Chittapur",
    "Sedam",
    "Chincholi",
    "Gulbarga Rural",
    "Gulbarga Dakshin",
    "Gulbarga Uttar",
    "Aland",
    "Basavakalyan",
    "Humnabad",
    "Bidar South",
    "Bidar",
    "Bhalki",
    "Aurad",
    "Raichur Rural",
    "Raichur",
    "Manvi",
    "Devadurga",
    "Lingsugur",
    "Sindhanur",
    "Maski",
    "Kushtagi",
    "Kanakagiri",
    "Gangawati",
    "Yelburga",
    "Koppal",
    "Shirahatti",
    "Gadag",
    "Ron",
    "Nargund",
    "Navalgund",
    "Kundgol",
    "Dharwad",
    "Hubli-Dharwad East",
    "Hubli-Dharwad Central",
    "Hubli-Dharwad West",
    "Kalghatgi",
    "Haliyal",
    "Karwar",
    "Kumta",
    "Bhatkal",
    "Sirsi",
    "Yellapur",
    "Hangal",
    "Shiggaon",
    "Haveri",
    "Byadgi",
    "Hirekerur",
    "Ranebennur",
    "Hoovina Hadagali",
    "Hagaribommanahalli",
    "Vijayanagara",
    "Kampli",
    "Siruguppa",
    "Bellary Rural",
    "Bellary City",
    "Sandur",
    "Kudligi",
    "Molakalmuru",
    "Challakere",
    "Chitradurga",
    "Hiriyur",
    "Hosadurga",
    "Holalkere",
    "Jagalur",
    "Harapanahalli",
    "Harihar",
    "Davanagere North",
    "Davanagere South",
    "Mayakonda",
    "Channagiri",
    "Honnali",
    "Shimoga Rural",
    "Bhadravati",
    "Shimoga",
    "Tirthahalli",
    "Shikaripura",
    "Sorab",
    "Sagar",
    "Byndoor",
    "Kundapura",
    "Udupi",
    "Kapu",
    "Karkala",
    "Sringeri",
    "Mudigere",
    "Chikmagalur",
    "Tarikere",
    "Kadur",
    "Chiknayakanhalli",
    "Tiptur",
    "Turuvekere",
    "Kunigal",
    "Tumkur City",
    "Tumkur Rural",
    "Koratagere",
    "Gubbi",
    "Sira",
    "Pavagada",
    "Madhugiri",
    "Gauribidanur",
    "Bagepalli",
    "Chikkaballapur",
    "Sidlaghatta",
    "Chintamani",
    "Srinivaspur",
    "Mulbagal",
    "Kolar Gold Field",
    "Bangarapet",
    "Kolar",
    "Malur",
    "Yelahanka",
    "Krishnarajapuram",
    "Byatarayanapura",
    "Yeshwantpur",
    "Rajarajeshwarinagar",
    "Dasarahalli",
    "Mahalakshmi Layout",
    "Malleshwaram",
    "Hebbal",
    "Pulakeshinagar",
    "Sarvagnanagar",
    "C. V. Raman Nagar",
    "Shivajinagar",
    "Shanti Nagar",
    "Gandhi Nagar",
    "Rajaji Nagar",
    "Govindraj Nagar",
    "Vijay Nagar",
    "Chamrajpet",
    "Chickpet",
    "Basavanagudi",
    "Padmanabhanagar",
    "B.T.M. Layout",
    "Jayanagar",
    "Mahadevapura",
    "Bommanahalli",
    "Bangalore South",
    "Anekal",
    "Hosakote",
    "Devanahalli",
    "Doddaballapur",
    "Nelamangala",
    "Magadi",
    "Ramanagaram",
    "Kanakapura",
    "Channapatna",
    "Malavalli",
    "Maddur",
    "Melukote",
    "Mandya",
    "Shrirangapattana",
    "Nagamangala",
    "Krishnarajapet",
    "Shravanabelagola",
    "Arsikere",
    "Belur",
    "Hassan",
    "Holenarasipur",
    "Arkalgud",
    "Sakleshpur",
    "Belthangady",
    "Moodabidri",
    "Mangalore City North",
    "Mangalore City South",
    "Mangalore",
    "Bantval",
    "Puttur",
    "Sullia",
    "Madikeri",
    "Virajpet",
    "Periyapatna",
    "Krishnarajanagara",
    "Hunsur",
    "Heggadadevankote",
    "Nanjangud",
    "Chamundeshwari",
    "Krishnaraja",
    "Chamaraja",
    "Narasimharaja",
    "Varuna",
    "T. Narasipur",
    "Hanur",
    "Kollegal",
    "Chamarajanagar",
    "Gundlupet",
  ];
  const kannadaConstituencyList = [
    "ನಿಪ್ಪಾಣಿ",
    "ಚಿಕ್ಕೋಡಿ-ಸದಲ್ಗಾ",
    "ಅತಾಣಿ",
    "ಕಾಗವಾಡ",
    "ಕುಡಚಿ",
    "ರೈಬಾಗ್",
    "ಹಕ್ಕೇರಿ",
    "ಅರಭಾವಿ",
    "ಗೋಕಾಕ್",
    "ಯೆಮ್ಕನ್ಮಾರುಡಿ",
    "ಬೆಳಗಾವು ಉತ್ತರ",
    "ಬೆಳಗಾವು ದಕ್ಷಿಣ",
    "ಬೆಳಗಾವು ಗ್ರಾಮೀಣ",
    "ಖಾನಾಪುರ",
    "ಕಿತ್ತೂರ",
    "ಬೈಲಹೊಂಗಲ",
    "ಸೌಂದತ್ತಿ ಯಲ್ಲಮ್ಮ",
    "ರಾಮದೂರುಗ",
    "ಮುದ್ದೋಳ",
    "ಟೆರ್‌ಡಲ್",
    "ಜಮಖಂಡಿ",
    "ಬಿಳಗಿ",
    "ಬದಾಮಿ",
    "ಬಾಗಲಕೋಟೆ",
    "ಹುಣಗುಂದ",
    "ಮುದ್ದೇಬಿಹಾಲ",
    "ದೇವರ ಹಿಪ್ಪargi",
    "ಬಸವಕಲ್ಯಾಣ",
    "ಬಬಲೇಶ್ವರ",
    "ಬಿಜಾಪುರ ನಗರ",
    "ನಗಥನ್",
    "ಇಂಡಿ",
    "ಸಿಂದಗಿ",
    "ಅಫಝಲ್ಪುರ್",
    "ಜೇವರ್ಗಿ",
    "ಶೋಣಾಪುರ",
    "ಶಹಾಪುರ",
    "ಯಾದಗಿರ್",
    "ಗುರ್ಮಿತ್ಕಲ್",
    "ಚಿಟ್ಟಾಪುರ",
    "ಸೆಡಂ",
    "ಚಿಂಚೋಳಿ",
    "ಗಲ್ಬರ್ಗಾ ಗ್ರಾಮೀಣ",
    "ಗಲ್ಬರ್ಗಾ ದಕ್ಷಿಣ",
    "ಗಲ್ಬರ್ಗಾ ಉತ್ತರ",
    "ಅಳಂಡ",
    "ಬಸವಕಲ್ಯಾಣ",
    "ಹುಮನಬಾದ್",
    "ಬಿದರ್ ದಕ್ಷಿಣ",
    "ಬಿದರ್",
    "ಭಾಲ್ಕಿ",
    "ಆುರಾಡ್",
    "ರೈಚೂರು ಗ್ರಾಮೀಣ",
    "ರೈಚೂರು",
    "ಮಣವಿ",
    "ದೇವದೂರುಗ",
    "ಲಿಂಗಸುಗೂರು",
    "ಸಿಂಧನೂರು",
    "ಮಸ್ಕಿ",
    "ಕುಷ್ಟಗಿ",
    "ಕನಕಗಿರಿ",
    "ಗಂಗಾವತಿ",
    "ಯೆಲ್ಬುರ್ಗ",
    "ಕೊಪ್ಪಳ",
    "ಶಿರಹಟ್ಟಿ",
    "ಗದಗ",
    "ರಾನ್",
    "ನಾರಗುಂದ",
    "ನವಲಗುಂದ",
    "ಕುಂಡಗೋಲ್",
    "ಧಾರವಾಡ",
    "ಹುಬ್ಬಳಿ-ಧಾರವಾಡ ಪೂರ್ವ",
    "ಹುಬ್ಬಳಿ-ಧಾರವಾಡ ಮಧ್ಯ",
    "ಹುಬ್ಬಳಿ-ಧಾರವಾಡ ಪಶ್ಚಿಮ",
    "ಕಲಘಟಗಿ",
    "ಹಳಿಯಲ್",
    "ಕಾರವಾರ",
    "ಕುಮಟಾ",
    "ಭಟ್ಕಳ",
    "ಸಿರಸಿ",
    "ಯೆಲ್ಲಾಪುರ",
    "ಹಂಗಾಲ್",
    "ಶಿಗ್ಗಾವೋನ",
    "ಹಾವೇರಿ",
    "ಬ್ಯಾಡಗಿ",
    "ಹಿರೇಕೆರೂರು",
    "ರಾಣೆಬೆನ್ನೂರು",
    "ಹೂವಿನ ಹಡಗಲಿ",
    "ಹಗರಿಬೊಮ್ಮನಹಳ್ಳಿ",
    "ವಿಜಯನಗರ",
    "ಕಂಪ್ಲಿ",
    "ಸಿರುಗುಪ್ಪ",
    "ಬೆಳ್ಳಾರಿ ಗ್ರಾಮೀಣ",
    "ಬೆಳ್ಳಾರಿ ನಗರ",
    "ಸಂಡೂರ",
    "ಕುಡ್ಲಿಗಿ",
    "ಮೋಳಕಲ್ಮೂರು",
    "ಚಳ್ಳಕೆರೆ",
    "ಚಿತ್ರದುರ್ಗ",
    "ಹಿರಿಯೂರು",
    "ಹೊಸದೂರುಗ",
    "ಹೋಲಾಳkere",
    "ಜಾಗಲುರ್",
    "ಹರಪನಹಳ್ಳಿ",
    "ಹರಿಹರ",
    "ದಾವಣಗೆರೆ ಉತ್ತರ",
    "ದಾವಣಗೆರೆ ದಕ್ಷಿಣ",
    "ಮಯಕೊಂಡ",
    "ಚನ್ನಗುಳಿ",
    "ಹೊನ್ನಾಳ",
    "ಶಿವಮೊಗ್ಗ ಗ್ರಾಮೀಣ",
    "ಭದ್ರಾವತಿ",
    "ಶಿವಮೊಗ್ಗ",
    "ತೀರ್ಥಹಳ್ಳಿ",
    "ಶಿಕಾರಿಪುರ",
    "ಸೊರಬ",
    "ಸಾಗರ",
    "ಬಿಂಡೂರ",
    "ಕುಂಡಾಪುರ",
    "ಉದಪಿ",
    "ಕಾಪು",
    "ಕರ್ಕಲ",
    "ಶ್ರಿಂಗೇರಿ",
    "ಮುದಿಗೆರೆ",
    "ಚಿಕಮಗಳೂರು",
    "ತಾರಿಕೆರ",
    "ಕಡುರ್",
    "ಚಿಕ್ನಾಯಕನಹಳ್ಳಿ",
    "ತಿಪ್ಟೂರು",
    "ತುರುವೇಕೆರೆ",
    "ಕುಣigal",
    "ತುಮಕೂರು ನಗರ",
    "ತುಮಕೂರು ಗ್ರಾಮೀಣ",
    "ಕೋರೆಟಗೇರಿ",
    "ಗುಬ್ಬಿ",
    "ಸಿರಾ",
    "ಪಾವಗಡ",
    "ಮಧುಗಿರಿ",
    "ಗೌರಿಬಿದನೂರು",
    "ಬಾಗೇಪಳ್ಳಿ",
    "ಚಿಕ್ಕಬಳ್ಳಾಪುರ್",
    "ಸಿಡ್ಲಘಟ್ಟ",
    "ಚಿಂಟಮಣಿ",
    "ಶ್ರೀನಿವಾಸಪುರ",
    "ಮೂಲಬಾಗಲ",
    "ಕೋಲಾರ ಚಿನ್ನದ ಹ fields",
    "ಬಂಗಾರಪೇಟೆ",
    "ಕೋಲಾರ",
    "ಮಲೂರು",
    "ಯelah೦ಕ",
    "ಕೃಷ್ಣರಾಜಪುರ",
    "ಬಯಟರಾಯನಪುರ",
    "ಯಶವಂತಪುರ",
    "ರಾಜರಾಜೇಶ್ವರಿನಗರ",
    "ದಸರಹಳ್ಳಿ",
    "ಮಹಾಲಕ್ಷ್ಮಿ ಲೇಔಟ್",
    "ಮಲ್ಲೇಶ್ವರಂ",
    "ಹೆಬ್ಬಲ್",
    "ಪುಲಕೇಶಿನಗರ",
    "ಸರ್ವಜ್ಞಾನನಗರ",
    "ಸಿ.ವಿ. ರಾಮನ್ ನಗರ",
    "ಶಿವಾಜಿನಗರ",
    "ಶಾಂತಿ ನಗರ",
    "ಗಾಂಧಿ ನಗರ",
    "ರಾಜಾಜಿನಗರ",
    "ಗೋವಿಂದರಾಜ ನಗರ",
    "ವಿಜಯನಗರ",
    "ಚಾಮರಾಜಪೇಟೆ",
    "ಚಿಕ್‌ಪೆಟ್",
    "ಬಸವನಗರ",
    "ಪದ್ಮನಾಭನಗರ",
    "ಬಿಟಿಎಂ ಲೇಔಟ್",
    "ಜಯನಗರ",
    "ಮಹಾದೇವಪುರ",
    "ಬೋಮ್ಮಣಹಳ್ಳಿ",
    "ಬೆಂಗಳೂರು ದಕ್ಷಿಣ",
    "ಅನೆಕಲ್",
    "ಹೋಸದೋಕೆ",
    "ದೇವನಹಳ್ಳಿ",
    "ದೊಡ್ಡಬಳ್ಳಾಪುರ",
    "ನೆಲಮಂಗಲ",
    "ಮಾಗಡಿ",
    "ರಾಮನಗರ",
    "ಕನಕಪುರ",
    "ಚನ್ನಪಟ್ಟಣ",
    "ಮಲವಳ್ಳಿ",
    "ಮದ್ದೂರು",
    "ಮೆಲುಕೋಟೆ",
    "ಮಂಡ್ಯ",
    "ಶ್ರಿರಂಗಪಟ್ನ",
    "ನಗಮಂಗಲ",
    "ಕೃಷ್ಣರಾಜಪೇಟೆ",
    "ಶ್ರಾವಣಬೆಳಗೋಲ",
    "ಅರ್ಸಿಕೇರಿ",
    "ಬೆಲುರ್",
    "ಹಾಸನ",
    "ಹೊಳೇನರಸಿಪುರ",
    "ಅರ್ಕಲ್ಗುಡ",
    "ಸಾಕ್ಲೇಶಪುರ",
    "ಬೆಲ್ತಂಗಡಿ",
    "ಮೂಡಬಿದ್ರಿ",
    "ಮಂಗಳೂರು ನಗರ ಉತ್ತರ",
    "ಮಂಗಳೂರು ನಗರ ದಕ್ಷಿಣ",
    "ಮಂಗಳೂರು",
    "ಬಂಟ್ವಾಳ",
    "ಪುತ್ತೂರು",
    "ಸುಲ್ಲಿಯಾ",
    "ಮಡಿಕೇರಿ",
    "ವಿರಾಜಪೇಟೆ",
    "ಪೆರಿಯಪಟ್ನ",
    "ಕೃಷ್ಣರಾಜನಗರ",
    "ಹುಣಸೂರು",
    "ಹೆಗ್ಗದದೇವನಕೋಟೆ",
    "ನಂಜನಗೂಡು",
    "ಚಾಮುಂಡೇಶ್ವರಿ",
    "ಕೃಷ್ಣರಾಜ",
    "ಚಾಮರಾಜ",
    "ನರಸಿಂಹರಾಜ",
    "ವಾರಣ",
    "ತ.ನರಸಿಪುರ",
    "ಹನುರ್",
    "ಕೊಲ್ಲೇಗಾಲ",
    "ಚಾಮರಾಜನಗರ",
    "ಗಂಡ್ಲುಪೇಟೆ",
  ];

  console.log("Language", selectedLanguage, isKannada);
  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
      }}
    >
      <CssBaseline />
      <AppBar sx={{ height: "100px" }} ref={topHeaderRef}>
        <Toolbar
          sx={{
            backgroundColor: "#02865A",
            height: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <CardMedia
            component="img"
            sx={{
              maxWidth: "150px",
              height: "auto",
            }}
            image={logo}
            onClick={() => {}}
          />

          <Box sx={{ display: { sm: "block" } }}>
            <Button
              key={"kannada"}
              sx={{ color: "#fff" }}
              onClick={() => {
                setSelecedLanguage("ಕನ್ನಡ");
              }}
            >
              {"ಕನ್ನಡ"}
            </Button>

            <Button
              key={"english"}
              sx={{ color: "#fff" }}
              onClick={() => {
                setSelecedLanguage("English");
              }}
            >
              {"English"}
            </Button>
          </Box>
        </Toolbar>
      </AppBar>

      <Box component="main" width={"100%"}>
        <Box height={headerHeight}>
          <DrawerHeader />
        </Box>
        <Box>
          <Grid container>
            <Grid size={{ xs: 12, sm: 12, md: 6 }}>
              <Grid
                container
                size={{ xs: 12, sm: 12, md: 12 }}
                sx={{
                  justifyContent: "center",
                }}
              >
                <Box>
                  <img
                    src={background}
                    alt=""
                    loading="eager"
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "contain",
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid size={{ xs: 12, sm: 12, md: 6 }} container>
              <Formik
                initialValues={initial}
                validate={(values) => {
                  let errors = {};

                  if (!values.name)
                    errors.name = isKannada
                      ? "ಹೆಸರು ಅಗತ್ಯವಿದೆ"
                      : "Name is required";
                  if (!values.gender)
                    errors.gender = isKannada
                      ? "ಲಿಂಗವನ್ನು ಆಯ್ಕೆಮಾಡಿ"
                      : "Select a gender";
                  if (!values.constituency)
                    errors.constituency = isKannada
                      ? "ಮಂಡಲವನ್ನು ಆಯ್ಕೆಮಾಡಿ"
                      : "Select a Constituency";
                  if (!values.village)
                    errors.village = isKannada
                      ? "ಗ್ರಾಮದ ಹೆಸರು ಅಗತ್ಯವಿದೆ"
                      : "Village name is required";

                  return errors;
                }}
                enableReinitialize={true}
                validateOnChange={true}
                validateOnBlur={true}
                onSubmit={(values, formikHelpers) => {}}
              >
                {({
                  errors,
                  isValid,
                  helperText,
                  touched,
                  dirty,
                  handleChange,
                  setFieldValue,
                  handleSubmit,
                  values,
                }) => (
                  <Form onSubmit={handleSubmit} style={{ width: "100%" }}>
                    <Box p={5} width={"100%"}>
                      <Grid item lg={12} md={12} sm={12} container pt={5}>
                        <Field
                          as={TextField}
                          variant="outlined"
                          placeholder={
                            isKannada
                              ? "ನಿಮ್ಮ ಹೆಸರನ್ನು ನಮೂದಿಸಿ"
                              : "Enter your Name"
                          }
                          id="name"
                          fullWidth
                          name="name"
                          error={Boolean(errors.name) && Boolean(touched.name)}
                          helperText={Boolean(touched.name) && errors.name}
                          className="col-12"
                        />
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} container pt={5}>
                        <Autocomplete
                          value={values.gender}
                          options={genderList}
                          onChange={(e, value) => {
                            setFieldValue("gender", value);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label=""
                              placeholder={
                                isKannada
                                  ? "ಲಿಂಗವನ್ನು ಆಯ್ಕೆಮಾಡಿ"
                                  : "Select Gender"
                              }
                              error={errors.gender}
                              helperText={errors.gender}
                            />
                          )}
                          fullWidth
                        />
                      </Grid>
                      {/* <Grid item lg={12} md={12} sm={12} container pt={5}>
                        <Autocomplete
                          value={values.constituency}
                          options={["Assembly"]}
                          onChange={(e, value) => {
                            console.log("value_constituency", value);
                            setFieldValue("constituency", value);
                            setFieldValue("constituency_sub", "");
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label=""
                              placeholder={
                                isKannada
                                  ? "ಮಂಡಲವನ್ನು ಆಯ್ಕೆಮಾಡಿ"
                                  : "Select Constituency"
                              }
                              error={errors.constituency}
                              helperText={errors.constituency}
                            />
                          )}
                          fullWidth
                        />
                      </Grid> */}

                      <Grid item lg={12} md={12} sm={12} container pt={5}>
                        <Autocomplete
                          value={values.constituency}
                          options={
                            isKannada
                              ? kannadaConstituencyList
                              : constituencyList
                          }
                          onChange={(e, value) => {
                            setFieldValue("constituency", value);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label=""
                              placeholder={
                                isKannada
                                  ? "ಮಂಡಲವನ್ನು ಆಯ್ಕೆಮಾಡಿ"
                                  : "Select Constituency"
                              }
                              error={errors.constituency}
                              helperText={errors.constituency}
                            />
                          )}
                          fullWidth
                        />
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} container pt={5}>
                        <Field
                          as={TextField}
                          variant="outlined"
                          placeholder={
                            isKannada
                              ? "ಗ್ರಾಮದ ಹೆಸರನ್ನು ನಮೂದಿಸಿ"
                              : "Enter Village Name"
                          }
                          id="village"
                          fullWidth
                          name="village"
                          error={
                            Boolean(errors.village) && Boolean(touched.village)
                          }
                          helperText={
                            Boolean(touched.village) && errors.village
                          }
                          className="col-12"
                        />
                      </Grid>

                      <Grid item lg={12} md={12} sm={12} container pt={5}>
                        <ReCAPTCHA
                          sitekey={"googleRecaptchaSiteKey"}
                          id="recaptcha"
                          onChange={(value) => {
                            console.log("Recaptcha_value", value);
                            setFieldValue("recaptcha", value);
                          }}
                          required
                          name="recaptcha"
                        />
                      </Grid>

                      <Box sx={{ mt: 2 }}>
                        <Button
                          variant="contained"
                          sx={{ backgroundColor: "#02865A" }}
                          onClick={() => {}}
                          fullWidth
                        >
                          {isKannada ? "ನಮ್ಮ ಜೊತೆಗೆಗೂಡಿ" : "Join Us"}
                        </Button>
                      </Box>
                    </Box>
                  </Form>
                )}
              </Formik>
            </Grid>
          </Grid>
        </Box>
        <Box height={footerHeight}></Box>
      </Box>
      <Paper
        ref={footerRef}
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          padding: 5,
        }}
        elevation={3}
      >
        <Typography component={"p"}>
          © 2024 JANATA DAL (SECULAR). All rights reserved
        </Typography>
        <Box>
          <Typography component={"p"}>Follow Us On</Typography>
          <Typography>
            <IconButton>
              <a
                style={{
                  width: "15px",
                  height: "15px",
                  textDecoration: "none",
                  color: "#02865A",
                }}
                target="_blank"
                href="https://www.facebook.com/JDSpartyofficial/"
                title="Facebook"
                className="fa fa-facebook ficon"
                rel="noreferrer"
              ></a>
            </IconButton>
            <IconButton>
              <a
                style={{
                  width: "15px",
                  height: "15px",
                  textDecoration: "none",
                  color: "#02865A",
                }}
                target="_blank"
                href="https://x.com/janatadal_s"
                title=" Twitter"
                className="fa fa-twitter ficon"
                rel="noreferrer"
              ></a>
            </IconButton>
          </Typography>
        </Box>
      </Paper>
    </Box>
  );
}

MainPage.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default MainPage;
